
import * as React from 'react';

export interface IncomingLogData {
    Values:{
        id:string;
        friendlyDateReported:string;
        friendlyTime:string;
        unit:string;
        callType:string;
        street:string;
        dateInserted:Date;
    }
}


export class LogDataHelper {
    public static Get911LogData = (): Promise<any> => {
        return fetch("https://get911data.azurewebsites.net/api/HttpTrigger1?code=vly/UFjRjnx0c2eEoi0yPduOlTGb7WNFVtWXuDzk3g1HWmHytf4VOA==")
    .then(response => {
        if (response.ok) {
        return response.json();
        } else {
        throw new Error('Something went wrong ...');
        }
    })
    }

    public static renderRawLogData = (rawLogData: IncomingLogData[]) => { return rawLogData.map((logEntry:IncomingLogData, index)=>{                
        return(<p key={index}>{logEntry.Values.id} 
        {logEntry.Values.friendlyDateReported}
        {logEntry.Values.friendlyTime}
        {logEntry.Values.unit}
        {logEntry.Values.callType}
        {logEntry.Values.street}</p>);
      });}; 
}