/// <reference path="../../Types/react-vis.d.ts"/> 
import React from 'react'
import './LatestActivity.css'
import { Container, Header, Icon, List } from 'semantic-ui-react'
import { IncomingLogData } from '../../Helpers/LogData';

interface ILatestActivityProps {
    entries: {[title: string]: Array<IncomingLogData>};
}

interface IHash {
    [title: string] :string;
} 


export default class LatestActivity extends React.Component<ILatestActivityProps, {entry: IncomingLogData[], mapUrl: string, log: any, typeCounter: number}> {
    
  constructor(props: ILatestActivityProps) {
    super(props);

    this.state = {
      entry: this.props.entries[Object.keys(this.props.entries)[0]],
      mapUrl: "https://www.google.com/maps/embed/v1/place?key=AIzaSyDZOviGhy-euUgUS3ueu4mrXq3ZImCwHtg&maptype=satellite&q=CLARKSVILLE TN",
      log: "",
      typeCounter: 0
    };

  }

  public listContent = "";
  public googleMapsUrl='https://www.google.com/maps/embed/v1/place?key=AIzaSyDZOviGhy-euUgUS3ueu4mrXq3ZImCwHtg&maptype=satellite';


  componentWillUpdate(){
  

  }
  
  render() {
    this.listContent = this.renderListContent();

    return (
        <div>
          <div style={{display:"inline-block", width:"50%"}}>
            <Container>
              <Icon size="huge" name='chevron left' className="chevronLeft" onClick={this.paginateEntries}/>
              <Header as='h2' icon className="logTitle">
                  <Icon size="massive" name='bolt' />
                  {this.state.entry ? 
                    this.state.entry[0].Values.callType : "No Records available!"}
              </Header>
              <Icon size="huge" name='chevron right' className="chevronRight" onClick={() => this.paginateEntries("forward")}/>
            </Container>

            <Header as='h3'>
              {this.state.entry ? 
              this.state.entry.length : "0"} Records
            </Header>
            <List divided relaxed celled className="logList">
              {this.props.entries ? 
                this.listContent : ""}
            </List>
          </div>
          <div style={{display:"inline-block", width:"50%"}}>
            <iframe className="logMap" src={this.state.mapUrl} title="GoogleMaps"></iframe>
          </div>
        </div>
    )
  }

  public renderListContent = (): any =>{

    let content: any = "";

    if(Object.entries(this.props.entries).length) {
        content = (this.props.entries[Object.keys(this.props.entries)[this.state.typeCounter]].sort(function (a:IncomingLogData, b:IncomingLogData) {
          return new Date(`1970/01/01 ${a.Values.friendlyTime}`).getTime() + new Date(`1970/01/01 ${b.Values.friendlyTime}`).getTime();
        })).map((index: IncomingLogData) => { 
              console.log(index);  
          if(index.Values.street !== ""){
            console.log('using street' + index.Values.street);
            this.googleMapsUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDZOviGhy-euUgUS3ueu4mrXq3ZImCwHtg&maptype=satellite&q='+index.Values.street;
          } else{
            index.Values.street = "Undisclosed Location"
          }

          return(
                <List.Item key={index.Values.id} onClick={() => this.updateMap(index.Values.street)}>
                  <List.Content>
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                      <List.Icon name='marker' size='big' verticalAlign='top' />
                    </div>
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                      <List.Header as='a'>{index.Values.street}</List.Header>
                      <List.Description as='a'>{index.Values.friendlyDateReported} - {index.Values.friendlyTime}</List.Description>
                    </div>
                  </List.Content>
                </List.Item>
            );
      });
   }

   return content;
  }
  public paginateEntries = (operation?: string) => {

    //Get the names of the various crimes
    let entryKeys = Object.keys(this.props.entries);

    if(operation === "forward"){
      //Move forward one crime index
      if(this.state.typeCounter === entryKeys.length-1) {
        this.setState({entry: this.props.entries[entryKeys[0]], mapUrl: this.defaultMapSelection, typeCounter: 0});
      } else{
        this.setState({entry: this.props.entries[entryKeys[this.state.typeCounter+1]], mapUrl: this.defaultMapSelection, typeCounter: this.state.typeCounter + 1});
      }
    } else {
      //Move backward one crime index
      if(this.state.typeCounter===0){
        this.setState({entry: this.props.entries[entryKeys[entryKeys.length-1]], mapUrl: this.defaultMapSelection, typeCounter: entryKeys.length-1});
      } else{
        this.setState({entry: this.props.entries[entryKeys[this.state.typeCounter-1]], mapUrl: this.defaultMapSelection, typeCounter: this.state.typeCounter - 1});
      }
    }
  }

  public updateMap = (street:string): any => {
    this.setState({mapUrl: 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDZOviGhy-euUgUS3ueu4mrXq3ZImCwHtg&maptype=satellite&q='+street});
  }

  public defaultMapSelection: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDZOviGhy-euUgUS3ueu4mrXq3ZImCwHtg&maptype=satellite&q=CLARKSVILLE TN';

}