import React, { Component } from 'react'
import {Menu} from 'semantic-ui-react'
import './LandingMenu.css';

export default class LandingMenu extends Component<{},{}> {

state = {
    activeItem: ''
}

handleItemClick = () => (e:Event) => this.setState({ activeItem: '' })

    public render(){
        return(
            <Menu stackable className='manageMyMenu'>
                <Menu.Item>
                    <img src='https://react.semantic-ui.com/logo.png' alt="logo" />
                </Menu.Item>
                {/*
                <Menu.Item
                    name='features'
                    active={this.state.activeItem === 'features'}
                    onClick={this.handleItemClick}
                >
                    Features
                </Menu.Item>
                */}
                <Menu.Item>
                    <div style={{color:"white"}}>
                        This site is under construction. 
                        We are preparing to bring you a place to view, share, and discuss all of the news relevant to Crime in Clarksville. 
                        <br/><br/> Contact us @ <a href="mailto:emailUs@clarksvillecrimewatch.com" style={{color:"white"}}>emailUs@clarksvillecrimewatch.com</a></div>
                </Menu.Item>
            </Menu>
        );
    }

}
