/// <reference path="../../Types/react-vis.d.ts"/> 
import React from 'react'
import './LandingPage.css';
import LandingMenu from '../../Components/LandingMenu/LandingMenu';
import {IncomingBookingData} from '../../Helpers/BookingData';
import {LogDataHelper, IncomingLogData} from '../../Helpers/LogData';
import LatestActivity from '../../Components/LatestActivity/LatestActivity';
import JustBooked from '../../Components/JustBooked/JustBooked';

export default class LandingPage extends React.Component<{}, {rawLogData:IncomingLogData[]; groomedLogData:any, isLoading:boolean; error: string; currentLogType: IncomingLogData[]; peopleData:IncomingBookingData[];}> {

    constructor(props: any) {

        super(props);
        this.state = {
          rawLogData: [],
          groomedLogData: [],
          isLoading: true,
          error: "",
          currentLogType: [],
          peopleData:[]
        };

    }

    public componentDidMount(){
        
        LogDataHelper.Get911LogData().then((data) => {
          this.setState({ rawLogData: data });
          console.log("got raw data");

        }).then(()=>{
          this.GroomData();
        }).then(() => {
          this.setState({ isLoading: false });
        }).catch(error => {
          this.setState({ error, isLoading: false })
        });
    }
//<HorizontalBarChart data={this.state.rawLogData} />
    public render(){
      if(this.state.isLoading===false)
      {
          return(
            <div className="landingPage">
                <div className="landingWrapper">
                    <LandingMenu />
                    <JustBooked  person={this.state.peopleData[0]} />
                    <LatestActivity entries={this.state.groomedLogData}></LatestActivity>
                </div>
            </div>
        );
      } else {
        return("Getting Data...");
      }

    }

  public GroomData = (): void => {

    //let radialChartDataArray:ChartTypes.RadialChartType[] = [];
    //let verticalBarSeriesDataArray:ChartTypes.VerticalBarSeriesType[] = [];
    console.log("grooming data");

    let logsByCallType: object & {[title: string]: Array<IncomingLogData>} = {};
      
    var options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    
    var d = new Date();

    var targetDate = new Date(d.setDate(d.getDate())).toLocaleDateString('en-Us', options).replace('/','').replace('/','');

    let todaysLog = this.state.rawLogData.filter((logEntry:IncomingLogData) => {return logEntry.Values.friendlyDateReported === targetDate});
    
    console.log(this.state.rawLogData)
    console.log(targetDate)
    //map all items to a RadialChart Data array data array then group all RadialChart Types by calltype/label
    todaysLog.forEach((logEntry: IncomingLogData, currentIndex) => {  
      //radialChartDataArray[currentIndex] = {label:logEntry.Values.callType};
      if(!logsByCallType.hasOwnProperty(logEntry.Values.callType))
      {
        logsByCallType = {...logsByCallType, ...{[logEntry.Values.callType]:[logEntry]}};
        
      }
      else{
        logsByCallType[logEntry.Values.callType].push(logEntry);
      }
    });

    console.log(logsByCallType);
    this.setState({groomedLogData:logsByCallType, currentLogType: logsByCallType[Object.keys(logsByCallType)[0]]});
  }
    
}