import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import LandingPage from './Pages/LandingPage/LandingPage'


const App: React.FC = () => {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  );
}
export default App;
