import React from 'react';


export interface IncomingBookingData {
   
        mcsotnid:string;
        keynumber:string;
        arrestdate:string;
        bookintime:string;
        releasedate:string;
        officer:string;
        namelfm:string;
        address:string;
        citystzip:string;
        debug_log:string;
        gender:string;
        race:string;
        pictureUrl:string;
        birthdate:string;
        countynum:string;
        created_at:string;
        updated_at:string;
        charges: IncomingChargeData[];
        lastname:string; 
        firstname:string;
        middlename:string;
        ourid:string;
}

export interface IncomingChargeData {
    mcsotn_charge_id:string;
    booking_id:string;
    charge:string;
    bond:string;
    bondagency:string;
    created_at:string;
    updated_at:string;
    keynumber:string;
    ourid:string;
}

export interface IJustBookedProps {
    person: IncomingBookingData;
}

interface IHash {
    [title: string] :string;
} 

export class BookingDataHelper {
    public static GetBookingLogData = (): Promise<any> => {
        return fetch("https://mcsotncountyjailrecords.azurewebsites.net/api/GetLatestBooked?code=3VWVjf7cQKoOGa1l33s2OT73TU5dysgeOBykX/7XnC9b/IHWWiEx0A==")
    .then(response => {
        if (response.ok) {
        return response.json();
        } else {
        throw new Error('Something went wrong ...');
        }
    })
    }

    public static renderRawLogData = (rawLogData: IncomingBookingData[]) => { return rawLogData.map((bookingEntry:IncomingBookingData, index)=>{                
        return(<p key={index}>{bookingEntry.ourid} 
        {bookingEntry.created_at}
        {bookingEntry.officer}
        {bookingEntry.charges[0].charge}
        {bookingEntry.firstname}
        {bookingEntry.lastname}</p>);
      });}; 
}