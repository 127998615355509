/// <reference path="../../Types/react-vis.d.ts"/> 
import React from 'react'
import './JustBooked.css'
import { Container, Header } from 'semantic-ui-react'
import { BookingDataHelper, IncomingBookingData, IJustBookedProps } from '../../Helpers/BookingData';


export default class JustBooked extends React.Component<IJustBookedProps, {person: IncomingBookingData}> {
    
  constructor(props: IJustBookedProps) {
    super(props);

    this.state = {
      person: this.props.person
    };

  }

  public listContent = "";


  componentWillMount(){

    BookingDataHelper.GetBookingLogData().then((data: any) => {

      data.charges = JSON.parse(data.charges)

      this.setState({ person: data });
      console.log("got BOOKING LOG data");

    }).then(()=>{
    
    });

  }
  
  render() {
    console.log(this.state.person)
    return (
        <div>
          <div style={{display:"inline-block", width:"50%"}}>
            <Container>
              <Header as='h2' icon className="logTitle">
               Just Booked!
              </Header>
            </Container>
            {this.state.person ? <div>
              <img alt="a person" src={this.state.person.pictureUrl} style={{"border":"black 3px solid", "width":"50%"}}/>
                                    <div>{this.state.person.firstname} {this.state.person.lastname}</div> 
                                    <div>{this.state.person.charges[0].charge}</div>
                                    <div>{this.state.person.arrestdate}</div>  
                                 </div> :
            ""}            
          </div>
        </div>
    )
  }

}